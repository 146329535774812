body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.userIMAGE{
  box-shadow:2px 2px 10px rgba(128, 128, 128, 0.493);
  height:20vh;
  display:flex;
  justify-content:center;
  align-items:center;
  overflow:hidden;
}
.DETAILS{
  /* border: 1px solid red; */
  margin: 0rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.DETAILS h6{
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 1.2rem;
}
.DETAILS label{
  font-size: 0.9rem;
}
.DETAILS span{
  margin-right: 0.5rem;
  font-weight: bold;
}
.orderVIEW{
  width: 100%;
  margin: 1rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.438);
}
.order_summary table{
  /* border: 1px solid red; */
  width: 100%;
}
.order_summary table,.order_summary table td, .order_summary table th{
  border: 1px #282b2b solid;
}
.order_summary table th{
  text-align: center;
  font-size: 1rem;
  color: #fff;
  font-weight: 400;
  padding: 5px 0;
  background: #282b2b;
}

.order_summary table tbody tr{
  margin-right: 5rem;
}
.order_summary table td{
  text-align: center;
  font-weight: bold;
}
.table_product{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem;
}
.table_product span{
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 0.6rem;
}
.product_title {
  width: 200px;
}
.product_description{
  width: 400px !important;
  padding: 0 0.6rem;
}
.product_description p{
  word-break: break-all;
}
.product_price{
  width: 200px;
  color: black;
}
.product_subtotal{
  width: 200px;
  color: black;
}
.product_index{
  width: 40px;
}
.product_qty{
  width: 40px;
}
.VARIANT_OPTIONS {
  margin-top: 1rem;
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.226); */
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.425);
  width: 100%;
  padding-top: 1rem;
  /* border-radius: 5px; */
  position: relative;
  left: 30px;
}
.ATTRIBUTE_OPTIONS{
  margin-top: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.226);
  /* box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.425); */
  width: 100%;
  padding-top: 1rem;
  /* border-radius: 5px; */
  position: relative;
  left: 30px;
}
.ATT_REM_BTN ,.ATT_REM_BTN2{
  text-align: right;
}
.ATT_REM_BTN{
  /* border: 2px solid rgba(128, 128, 128, 0.226); */
  padding: 0.5rem;
}
.ATT_REM_BTN span{
  border: none;
  padding: 0.2rem 0.5rem;
  background: #282b2b;
  border: 1px solid #fff;
  margin-bottom: 0.5rem;
  color: white;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  transition: 300ms all ease;
}
.ATT_REM_BTN2 button{
  border: none;
  padding: 0rem 0.5rem;
  background: #282b2b;
  border: 1px solid #fff;
  margin-bottom: 0.5rem;
  color: white;
  outline: none;
  border-radius: 4px;
  transition: 300ms all ease;
}
.ATT_REM_BTN span:hover, .ATT_REM_BTN2 button:hover{
  background: #fff;
  font-weight: bold;
  border: 1px solid #282b2b;
  color: #282b2b;
}
.FONT_WEIGHT{
  font-weight: bold;
}
.FONT_WEIGHT2{
  font-size: 1.05rem;
  font-weight: bold;
}
.FONT_SIZE{
  font-weight: bold;
  font-size: 1rem;
}
.DATA_SAVE{
  display: flex;
  justify-content: right;
  margin-top: 2rem;
}
.UPLOAD_Container{
  display: grid;
  grid-template-columns: auto 200px;
  gap: 1rem;
  margin-bottom: 2rem;
}
.UPLOADED_FILES{
  height: 200px;
  padding: 0.7rem;
  display: flex;
  flex-direction: row;
  box-shadow:inset 3px 3px 10px rgba(128, 128, 128, 0.219);
  overflow-x: scroll;
  position: relative;
  border-radius: 5px;
}
.UPLOADED_FILES p{
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(128, 128, 128, 0.445);
  position: absolute;
  top:45%;
  left:40%;
  z-index: 1;
}
.UPLOADED_FILES div {
  width:170px;
  height: 100%;
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.466);
  margin-right: 1rem;
  position: relative;
  z-index: 3;
}
.REMOVE{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.836);
  font-weight: bold;
  letter-spacing: 2px;
  cursor: pointer;
  background: #282b2b70;
  opacity: 0;
  transition: 300ms all ease;
}
.REMOVE:hover{
  opacity:1;
}
.UPLOAD_BTN{
  box-shadow: 3px 3px 10px rgba(128, 128, 128, 0.466);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.UPLOAD_BTN:hover{
  background: rgba(128, 128, 128, 0.13);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.425);
}
.UPLOAD_BTN label{
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.UPLOAD_BTN label span{
  font-size: 1.1rem;
  font-weight:bold;
  margin-top: 1rem;
  color: black;
}
.BLOCKS > div{
  margin-bottom: 2rem;
}
.quill{
  height: 20rem;
}

.AddressModal{
  /* margin: 10px; */
  margin-top: 25px;
  width: 500px;
  height: 500px;
  gap: 2px;
}

.inputF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid rgb(255, 21, 4); */
  /* gap: 50px; */
}
.inputF label{
  margin-bottom: 0;
  font-weight: 600;
  font-size: 15px;
}
.inputF input{
    width: 350px;
}